.form-goupe {
    margin-bottom: 20px;
}
#animation-lottie {
  position: absolute !important;
  top: -92px !important;
  left: -15px !important;

}
/*the winner text*/
.mounir {    
        position: absolute;
        text-align: center;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        color: rgba(255,0,0,0.1);
        font-size: 70px;
        line-height: 70px;
        text-transform: uppercase;
        font-weight: 700;
        background-size: cover;
        background-image: url(https://cdn-images-1.medium.com/max/2000/1*Jalb56N34pBIGCjQULtW3A.jpeg);
        -webkit-background-clip: text;
        animation: background-text-animation 15s linear infinite;
}
.h1-looser {
  position: absolute;
  text-align: center;
  left: 70px;
  top:20%;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  font-weight: 600;
}
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: 100px;
  position: relative;
  left: 450px;
  top:-20px;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}
@keyframes background-text-animation {
    0%{
      background-position: left 0px top 50%;
    }
    50%{
      background-position: left 1500px top 50%;
    }
    100%{
      background-position: left 0px top 50%;
    }
  }
 /*the 3 explosion*/
 

  .firework  {
    position: absolute;
    z-index:200;
  }
  .explosion {
    z-index:200;
    position: absolute;
    left: -2px;
    bottom: 0;
    width: 4px;
    height: 80px;
    transform-origin: 50% 100%;
    /* background-color: rgba(0,0,0,.2); */
    overflow: hidden;
  }
  .explosion:nth-child(1) {
    transform: rotate(0deg) translateY(-15px);
  }
  .explosion:nth-child(2) {
    transform: rotate(30deg) translateY(-15px);
  }
  .explosion:nth-child(3) {
    transform: rotate(60deg) translateY(-15px);
  }
  .explosion:nth-child(4) {
    transform: rotate(90deg) translateY(-15px);
  }
  .explosion:nth-child(5) {
    transform: rotate(120deg) translateY(-15px);
  }
  .explosion:nth-child(6) {
    transform: rotate(150deg) translateY(-15px);
  }
  .explosion:nth-child(7) {
    transform: rotate(180deg) translateY(-15px);
  }
  .explosion:nth-child(8) {
    transform: rotate(210deg) translateY(-15px);
  }
  .explosion:nth-child(9) {
    transform: rotate(240deg) translateY(-15px);
  }
  .explosion:nth-child(10) {
    transform: rotate(270deg) translateY(-15px);
  }
  .explosion:nth-child(11) {
    transform: rotate(300deg) translateY(-15px);
  }
  .explosion:nth-child(12) {
    transform: rotate(330deg) translateY(-15px);
  }
  
  .explosion::before {
    z-index:200;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 40px;
    background-color: #f5cf52;
  }
  @keyframes explosion {
    0% {
      top: 100%;
    }
    33%, 100% {
      top: -50%;
    }
  }
  
  #firework1 {
    z-index:200;
    left: 50%;
    top: 50%;
    transform: scale(1);
  }
  #firework1 .explosion::before  {
    z-index:200;
    animation: explosion 2s ease-in-out infinite;
  }
  
  #firework2  {
    z-index:200;
    left: 40%;
    top: 45%;
    transform: scale(.7);
  }
  #firework2 .explosion::before  {
    z-index:200;
    animation: explosion 2s .6s ease-in-out infinite;
  }
  
  #firework3  {
    z-index:200;
    left: 60%;
    top: 65%;
    transform: scale(.4);
  }
  #firework3 .explosion::before  {
    z-index:200;
    animation: explosion 2s .4s ease-in-out infinite;
  }
  
  
  /*Fireworks*/
  
  .pyro > .before, .pyro > .after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
    -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }
  
  .pyro > .after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s; }
  
  @-webkit-keyframes bang {
    from {
      box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @-moz-keyframes bang {
    from {
      box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @-o-keyframes bang {
    from {
      box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @-ms-keyframes bang {
    from {
      box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @keyframes bang {
    from {
      box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }
  @-webkit-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0; } }
  @-moz-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0; } }
  @-o-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0; } }
  @-ms-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0; } }
  @keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0; } }
  @-webkit-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%; }
  
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%; }
  
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%; }
  
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%; }
  
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%; } }
  @-moz-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%; }
  
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%; }
  
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%; }
  
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%; }
  
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%; } }
  @-o-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%; }
  
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%; }
  
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%; }
  
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%; }
  
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%; } }
  @-ms-keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%; }
  
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%; }
  
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%; }
  
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%; }
  
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%; } }
  @keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%; }
  
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%; }
  
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%; }
  
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%; }
  
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%; } }
  

