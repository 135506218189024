body {
  /* Should also change SpinWheel.vue */
  --font-family: 'Suez One', 'Mochiy Pop P One', 'Jua', 'Unbounded', 'Mitr', 'Noto Sans TC',
    'Noto Sans SC', 'Noto Sans Lao', 'Noto Color Emoji';
  font-family: var(--font-family);
  /* For font checking */
  /* http://localhost:5173?group=Font%20test&data=eJw1jr9OwlAUxnefog_AUn0GF2fj7mCwgVQTWHRqqaYBWhgQCkqsklsh1dZGRMpFZMH99g0u9r_RpI_gbYnD-ZLz-76c8-2y-SJTOqa3d3L0VjjjPdsIxGo81kLxOiUmR4gPr2Jb3ZBA0by56fJ1l9fdyqMr1DYweB9ETzeRboQTyV-0gl4l5fLUv6yvpKPP-zLDHhLiP5tBR8TwFsMXDPVMtUyHqStbPnzAwMDgAwMLA0Bh0MYaR_2zaaZNDHgS95YX6M4RHN7hEUQ2mqWNhSZ5GXUNR0DQ4ZDlKGhBEhJJWMSP1dZvu_89MX46r2SN3mq-rOwza7hk89QBs56L5bT4eEBO7K1652cF6vSkWCowhCZqlyPTSNTh6Ks_StRGNUf_ActcwdU */

  --background-color: #212f56;

  background: var(--background-color);
  background: -moz-radial-gradient(center, circle cover, var(--background-color) 0%, #000000 100%);
  background: -webkit-radial-gradient(center, circle cover, var(--background-color) 0%, #000000 100%);
  background: radial-gradient(circle at center, var(--background-color) 0%, #000000 100%);
  background-attachment: fixed;

  margin: 0;

  /**
    * When a dialog modal is triggered, this value is used to hide the scrollbar and add a margin-right to the body.
    * This fix is necessary when using ScrollPanel and there is no scrollbar width.
    */
  --scrollbar-width: 0 !important;
}

/* .p-tooltip {
  font-family: 'Rock Salt';
  font-size: 0.75rem;
  text-align: center;
  font-weight: 700;
}

:not(li) > a {
  color: var(--primary-color);
  text-decoration: none;
}

:not(li) > a:hover {
  text-decoration: underline;
}
 */
 


/*
.lightrope {
 
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: fixed;
  top: 0; 
  left: 0;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.lightrope li {
  position: relative;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: #00f7a5;
  box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  -webkit-animation-name: flash-1;
  animation-name: flash-1;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n+1) {
  background: cyan;
  box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
  -webkit-animation-name: flash-2;
  animation-name: flash-2;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n+2) {
  background: #f70094;
  box-shadow: 0px 4.66667px 24px 3px #f70094;
  -webkit-animation-name: flash-3;
  animation-name: flash-3;
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n+1) {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.33333px;
  border-radius: 3px;
  top: -4.66667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.66667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@-webkit-keyframes flash-1 {
  0%, 100% {
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-1 {
  0%, 100% {
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
  }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@-webkit-keyframes flash-2 {
  0%, 100% {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px cyan;
  }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-2 {
  0%, 100% {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px cyan;
  }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@-webkit-keyframes flash-3 {
  0%, 100% {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px #f70094;
  }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

@keyframes flash-3 {
  0%, 100% {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px #f70094;
  }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}



*/
