.lightrope {
 
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: fixed;
    top: 0; 
    left: 0;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.66667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: cyan;
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.66667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.33333px;
    border-radius: 3px;
    top: -4.66667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.66667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
    0%, 100% {
      background: #00f7a5;
      box-shadow: 0px 4.66667px 24px 3px #00f7a5;
    }
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
    0%, 100% {
      background: #00f7a5;
      box-shadow: 0px 4.66667px 24px 3px #00f7a5;
    }
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
    0%, 100% {
      background: cyan;
      box-shadow: 0px 4.66667px 24px 3px cyan;
    }
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
    0%, 100% {
      background: cyan;
      box-shadow: 0px 4.66667px 24px 3px cyan;
    }
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
    0%, 100% {
      background: #f70094;
      box-shadow: 0px 4.66667px 24px 3px #f70094;
    }
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
    0%, 100% {
      background: #f70094;
      box-shadow: 0px 4.66667px 24px 3px #f70094;
    }
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  .modal-success {
    display: block;
    /* display: none; */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
  }
  
  .modal-success .modal-content-success {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    max-width: 630px;
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation-name: modalopen;
    animation-name: modalopen;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  
  .modal-success .modal-content-success .close-success {
    position: absolute;
    right: 0;
    font-size: 3rem;
    margin: 11px 16px 0 0;
    color: #b5b9be;
    cursor: pointer;
    padding: 0 10px;
  }
  .modal-success .modal-content-success .box-padding-success {
    text-align: center;
    padding: 16px;
  }
  
  .modal-success .modal-content-success .box-padding-success .button-success {
    display: block;
    color: #ff5702;
    text-decoration: none;
    width: 100%;
    border: 2px solid #ff5702;
    padding: 8px 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  }
  /**
   * Extracted from: SweetAlert
   * Modified by: Istiak Tridip
   */
  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
  }
  .success-checkmark .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #ff5702;
  }
  .success-checkmark .check-icon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
  }
  .success-checkmark .check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
  }
  .success-checkmark .check-icon::before,
  .success-checkmark .check-icon::after {
    content: "";
    height: 100px;
    position: absolute;
    background: #ffffff;
    transform: rotate(-45deg);
  }
  .success-checkmark .check-icon .icon-line {
    height: 5px;
    background-color: #ff5702;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
  }
  .success-checkmark .check-icon .icon-line.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 0.75s;
  }
  .success-checkmark .check-icon .icon-line.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 0.75s;
  }
  .success-checkmark .check-icon .icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(255, 87, 2, 0.5);
  }
  .success-checkmark .check-icon .icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #ffffff;
  }
  
  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
    }
  }
  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }
  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }
    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
  
  .remove-modal {
    display: none;
  }
  
  
 
