.imagee {
    width: 400px;
    height: 300px;
    text-align: center;

}
.p {
    text-align: right;
    padding: 20px 0px 20px 0px;
    color: black;
    max-height: 300px;
    word-wrap: break-word;
}
.container-div {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Set height to 100% of viewport height */
    width: 60vw; /* Set width to 100% of viewport width plus 20px */
    background-color: #ffffff; /* Set background color */
    overflow-x: auto;
}
.title {
    padding-top: 100px;
}
@media only screen and (max-width: 800px) {
    .container-div {
        display:felx;
        flex-direction: column;
        min-height: 100vh; /* Set height to 100% of viewport height */
        width: 40vh; /* Set width to 100% of viewport width plus 20px */
        background-color: #ffffff; /* Set background color */
        overflow-x: auto;
    }
    .title {
        padding-top: 40px;
        font-size: 50px !important;
    }
    .price {
        padding:20px 0 20px 0;
        font-size: 30px;
        text-align:center;
        word-wrap: break-word;
    }
  }

  /* Subscription FestivAL Template style */
  :root {
    --white-color:                  #ffffff;
    --primary-color:                #F8CB2E;
    --secondary-color:              #EE5007;
    --section-bg-color:             #f0f8ff;
    --custom-btn-bg-color:          #EE5007;
    --custom-btn-bg-hover-color:    #c01f27;
    --dark-color:                   #000000;
    --p-color:                      #717275;
    --border-color:                 #7fffd4;
    --link-hover-color:             #B22727;
  
    --body-font-family:             'Outfit', sans-serif;
  
    --h1-font-size:                 74px;
    --h2-font-size:                 46px;
    --h3-font-size:                 32px;
    --h4-font-size:                 28px;
    --h5-font-size:                 24px;
    --h6-font-size:                 22px;
    --p-font-size:                  18px;
    --btn-font-size:                14px;
    --copyright-font-size:          16px;
  
    --border-radius-large:          100px;
    --border-radius-medium:         20px;
    --border-radius-small:          10px;
  
    --font-weight-light:            300;
    --font-weight-normal:           400;
    --font-weight-bold:             700;
  }

  /*site header*/
  .site-header {
    background-color: var(--primary-color);
    padding-top: 12px;
    padding-bottom: 12px;
  }

  /*navnbar*/


  .sticky-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 51px;
  }
  
  .sticky-wrapper.is-sticky .navbar {
    background-color: var(--dark-color);
  }
  
  .navbar {
    background: transparent;
    z-index: 9;
     }
  
  .navbar-brand,
  .navbar-brand:hover {
    color: var(--white-color);
    font-size: var(--h5-font-size);
    font-weight: var(--font-weight-bold);
  }
  
  .navbar-expand-lg .navbar-nav .nav-link {
    border-radius: var(--border-radius-large);
    margin: 10px;
    padding: 10px 20px;
  }
  
  .navbar-nav .nav-link {
    display: inline-block;
    color: var(--white-color);
    font-size: var(--p-font-size);
    font-weight: var(--font-weight-normal);
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .navbar-nav .nav-link.active, 
  .navbar-nav .nav-link:hover {
    color: var(--secondary-color);
  }
  
  .navbar-toggler {
    border: 0;
    padding: 0;
    cursor: pointer;
    margin: 0;
    width: 30px;
    height: 35px;
    outline: none;
  }
  
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background: transparent;
  }
  
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
    transition: top 300ms 50ms ease, transform 300ms 350ms ease;
    transition: top 300ms 50ms ease, transform 300ms 350ms ease, -webkit-transform 300ms 350ms ease;
    top: 0;
  }
  
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: rotate(45deg);
  }
  
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(-45deg);
  }
  
  .navbar-toggler .navbar-toggler-icon {
    background: var(--white-color);
    transition: background 10ms 300ms ease;
    display: block;
    width: 30px;
    height: 2px;
    position: relative;
  }
  
  .navbar-toggler .navbar-toggler-icon:before,
  .navbar-toggler .navbar-toggler-icon:after {
    transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
    transition: top 300ms 350ms ease, transform 300ms 50ms ease;
    transition: top 300ms 350ms ease, transform 300ms 50ms ease, -webkit-transform 300ms 50ms ease;
    position: absolute;
    right: 0;
    left: 0;
    background: var(--white-color);
    width: 30px;
    height: 2px;
    content: '';
  }
  
  .navbar-toggler .navbar-toggler-icon::before {
    top: -8px;
  }
  
  .navbar-toggler .navbar-toggler-icon::after {
    top: 8px;
  }
  /*custom button */
  .custom-btn {
    background: var(--custom-btn-bg-color);
    border: 2px solid transparent;
    border-radius: var(--border-radius-large);
    color: var(--white-color);
    font-size: var(--btn-font-size);
    font-weight: var(--font-weight-bold);
    line-height: normal;
    transition: all 0.3s;
    padding: 10px 20px;
  }
  
  .custom-btn:hover {
    background: var(--custom-btn-bg-hover-color);
    color: var(--white-color);
  }
  
  .custom-border-btn {
    background: transparent;
    border: 2px solid var(--custom-btn-bg-color);
    color: var(--custom-btn-bg-color);
  }


  /*Hero*/
  .hero-section {
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    height: calc(100vh - 51px);
    
  }
  
  .hero-section small {
    color: var(--white-color);
    text-transform: uppercase;
  }
  
  .hero-section .section-overlay {
     z-index: 2;
    opacity: 0.45; 
  }
  
  .hero-section .container {
    position: relative;
    z-index: 2;
    height: 100%;
    padding-bottom: 50px;
  }
  
  .hero-section .container .row {
    height: 100%;
  }


  /*---------------------------------------
  VIDEO              
-----------------------------------------*/
.video-wrap {
  z-index: -100;
}

.custom-video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

  
  /*---------------------------------------
  SOCIAL ICON               
-----------------------------------------*/
.social-icon {
    margin: 0;
    padding: 0;
  }
  
  .social-icon-item {
    list-style: none;
    display: inline-block;
    vertical-align: top;
  }
  
  .social-icon-link {
    background: var(--secondary-color);
    border-radius: var(--border-radius-large);
    color: var(--white-color);
    font-size: var(--copyright-font-size);
    display: block;
    margin-right: 10px;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 36px;
    transition: background 0.2s, color 0.2s;
  }
  
  .social-icon-link:hover {
    background: var(--primary-color);
    color: var(--white-color);
  }
  
  .social-icon-link span {
    display: block;
  }
  
  .social-icon-link span:hover::before{
    animation: spinAround 2s linear infinite;
  }

  
/*---------------------------------------
  ABOUT              
-----------------------------------------*/
.about-section {
    /* background-image: url('../images/edward-unsplash-blur.jpg'); */
    background-color: #704010;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .about-image {
    border-radius: var(--border-radius-medium);
    display: block;
  }
  
  .about-text-wrap {
    position: relative;
  }
  
  .about-text-icon {
    background: var(--primary-color);
    border-radius: 100%;
    font-size: var(--h3-font-size);
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
  }
  
  .about-text-info {
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: var(--border-radius-medium);
    border: 1px solid rgba(209, 213, 219, 0.3);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 20px;
    padding: 35px;
  }
  

  /*---------------------------------------
  SECTION               
-----------------------------------------*/
.section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  .section-bg {
    background-color: var(--section-bg-color);
  }
  
  .section-overlay {
    background-color: var(--dark-color);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 0.35;
  }
  
  .section-overlay + .container {
    position: relative;
  }
  


  /*---------------------------------------
  ARTISTS              
-----------------------------------------*/
.artists-thumb {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
  }
  
  .artists-image {
    border-radius: var(--border-radius-medium);
    display: block;
    width: 100%;
  }
  
  .artists-thumb:hover .artists-hover {
    transform: translateY(0);
    opacity: 1;
  }
  
  .artists-hover {
    background-color: var(--primary-color);
    background-color: rgba(248, 203, 46, 0.75);
    border-radius: var(--border-radius-medium);
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    margin: 20px;
    padding: 35px;
    transition: all 0.5s ease;
    transform: translateY(100%);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
  }
  
  .artists-hover p strong {
    color: var(--white-color);
    display: inline-block;
    min-width: 180px;
    margin-right: 20px;
  }
  
  .artists-hover p a {
    color: var(--secondary-color);
  }
  
  .artists-hover p a:hover {
    color: var(--white-color);
  }
  
  .artists-hover hr {
    margin: 1.5rem 0;
  }

  
  /*---------------------------------------
  CUSTOM FORM               
-----------------------------------------*/
.custom-form .form-control {
    color: var(--p-color);
    margin-bottom: 24px;
    padding-top: 13px;
    padding-bottom: 13px;
    outline: none;
  }
  
  .custom-form button[type="submit"] {
    background: var(--custom-btn-bg-color);
    border: none;
    border-radius: var(--border-radius-large);
    color: var(--white-color);
    font-size: var(--p-font-size);
    font-weight: var(--font-weight-medium);
    transition: all 0.3s;
    margin-bottom: 0;
  }
  
  .custom-form button[type="submit"]:hover,
  .custom-form button[type="submit"]:focus {
    background: var(--custom-btn-bg-hover-color);
    border-color: transparent;
  }
  

  .tabb-contentt {
    background-color: var(--white-color);
    border-radius: var(--border-radius-medium);
    padding: 45px;
  }
  /*---------------------------------------
  SITE FOOTER              
-----------------------------------------*/
.site-footer {
    background-color: var(--dark-color);
    position: relative;
    overflow: hidden;
    padding-bottom: 30px;
  }
  
  .site-footer-top {
    background-color: var(--secondary-color);
    /* background-image: url('../images/nainoa-shizuru-NcdG9mK3PBY-unsplash.jpg'); */
    background-repeat: no-repeat;
    margin-bottom: 70px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .site-footer-bottom {
    border-top: 1px solid #1f1c1c;
    margin-top: 60px;
  }
  
  .site-footer-title {
    color: var(--primary-color); 
  }
  
  .site-footer-link,
  .copyright-text {
    color: var(--white-color);
  }
  
  .site-footer-links {
    padding-left: 0;
  }
  
  .site-footer-link-item {
    list-style: none;
    display: inline-block;
    margin-right: 15px;
  }
  
  .copyright-text {
    font-size: var(--copyright-font-size);
  }

  /*********************Typography *********************/

  .link-fx-1 {
    color: var(--white-color);
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 0 6px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .link-fx-1:hover {
    color: var(--link-hover-color);
  }
  
  .link-fx-1:hover::before {
    transform: translateX(17px) scaleX(0);
    transition: transform .2s;
  }
  
  .link-fx-1:hover .icon circle {
    stroke-dashoffset: 200;
    transition: stroke-dashoffset .2s .1s;
  }
  
  .link-fx-1:hover .icon line {
    transform: rotate(-180deg);
  }
  
  .link-fx-1:hover .icon line:last-child {
    transform: rotate(180deg);
  }
  
  .link-fx-1::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform-origin: right center;
    transition: transform .2s .1s;
  }
  
  .link-fx-1 .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(100%) rotate(90deg);
    font-size: 32px;
  }
  
  .icon {
    --size: 1em;
    height: var(--size);
    width: var(--size);
    display: inline-block;
    color: inherit;
    fill: currentColor;
    line-height: 1;
    flex-shrink: 0;
    max-width: initial;
  }
  
  .link-fx-1 .icon circle {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    transition: stroke-dashoffset .2s;
  }
  
  .link-fx-1 .icon line {
    transition: transform .4s;
    transform-origin: 13px 15px;
  }
  
  .link-fx-1 .icon line:last-child {
    transform-origin: 19px 15px;
  }

 
  a {
    display: inline-block;
    color: var(--primary-color);
    text-decoration: none;
  }
  
  a:hover {
    color: var(--link-hover-color);
  }
  
  b,
  strong {
    font-weight: var(--font-weight-bold);
  }

  







  



p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

.ul>li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-light);
}

/*---------------------------------------
  RESPONSIVE STYLES               
-----------------------------------------*/
@media screen and (max-width: 991px) {
  h1 {
    font-size: 62px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }


  .navbar-expand-lg .navbar-nav {
    padding-bottom: 30px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
  }

  .hero-section {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) {
  .custom-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}

/*---------------------------------------
  CUSTOM ICON COLOR               
-----------------------------------------*/
.custom-icon {
  color: var(--secondary-color);
}

