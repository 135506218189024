@media only screen and (max-width: 400px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 280px;
    --wheel-slice-spacing: 25px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 400px) and (max-width: 450px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 350px;
    --wheel-slice-spacing: 25px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 400px;
    --wheel-slice-spacing: 30px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 550px) and (max-width: 600px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 450px;
    --wheel-slice-spacing: 30px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 500px;
    --wheel-slice-spacing: 35px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 500px;
    --wheel-slice-spacing: 35px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }

  div.row {
    display: block;
  }
}

@media only screen and (min-width: 800px) and (max-width: 991px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 530px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }

  div.row {
    display: block;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1165px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 430px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 1165px) and (max-width: 1425px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 530px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

@media only screen and (min-width: 1425px) {
  :root {
    --wheel-font: "Lato", "Quicksand", sans-serif;
    --wheel-size: 630px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #d38c12;
    --neutral-color: white;
    --font-sizee: 16px;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 10s;
    --reset-duration: 0.25s;
  }
}

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 20px;
  margin: auto;
  background-color: #ffb810;
  border: solid var(--wheel-color) 3px;
  border-radius: 50%;
  user-select: none;
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  border: solid transparent 20px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

/* Roue */
.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  background-color: var(--wheel-color);
  transition: transform var(--reset-duration);
  transform: rotate(0deg);
  cursor: pointer;
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
  display: block;
  position: absolute;
  content: "";
  background-color: var(--neutral-color);
  width: 85px;
  height: 85px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: inset 0 0 21px 10px var(--neutral-color), 0 0 0 13px rgba(0, 0, 0, 0.1);
}

/* Element sur la roue */
.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  /* position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  /* texte */
  color: var(--neutral-color);
  font-size: var(--font-sizee);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
  content: " ";
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
      Largeur du triangle
      Je ne sais pas pourquoi j'ai besoin d'ajouter 
        " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
      mais globalement ca marche a peu près bien 
      TODO: A étudier 
    */
  --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
  --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
  border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}


.wheel-item {
  font-size: var(--font-sizee) !important;
}

/******************************************/
/****************************************/
/******************************************/
/****************************************/
/******************************************/
/****************************************/
/****************************************/
/******************************************/
/****************************************/
/******************************************/
/****************************************/
/****************************************/
/******************************************/
/****************************************/
/******************************************/
/****************************************/


.ag-wheel_lamp {
  height: 16px;
  width: 16px;
  background-color: #fff;

  z-index: 3;
  position: absolute;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.7), inset 0 0 5px 0 rgba(236, 170, 68, 0.45), 0 0 90px 35px rgba(255, 255, 157, 0.35);
  -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.7), inset 0 0 5px 0 rgba(236, 170, 68, 0.45), 0 0 90px 35px rgba(255, 255, 157, 0.35);
  -o-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.7), inset 0 0 5px 0 rgba(236, 170, 68, 0.45), 0 0 90px 35px rgba(255, 255, 157, 0.35);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.7), inset 0 0 5px 0 rgba(236, 170, 68, 0.45), 0 0 90px 35px rgba(255, 255, 157, 0.35);
}
.ag-wheel_lamp:before {
  content: "";
  height: 50px;
  width: 50px;
  background: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/wheel-rotate/images/shine.png) 50% no-repeat;
  background-size: 145%;

  opacity: 1;

  position: absolute;
  top: -17px;
  left: -17px;
}
.ag-wheel_lamp:nth-child(odd)::before {
  -webkit-animation: an-shine 1s ease infinite;
  -moz-animation: an-shine 1s ease infinite;
  -o-animation: an-shine 1s ease infinite;
  animation: an-shine 1s ease infinite;
}
.ag-wheel_lamp:nth-child(2n)::before {
  -webkit-animation: an-shine 1s ease 0.5s infinite;
  -moz-animation: an-shine 1s ease 0.5s infinite;
  -o-animation: an-shine 1s ease 0.5s infinite;
  animation: an-shine 1s ease 0.5s infinite;
}
.ag-wheel_lamp-1 {
  top: 11px;
  left: 205px;
}
.ag-wheel_lamp-2 {
  top: 11px;
  right: 205px;
}
.ag-wheel_lamp-11 {
  top: 121px;
  left: 50px;
}
.ag-wheel_lamp-3 {
  top: 121px;
  right: 50px;
}
.ag-wheel_lamp-10 {
  top: 290px;
  left: 3px;
}
.ag-wheel_lamp-4 {
  top: 240px;
  right: 3px;
}
.ag-wheel_lamp-9 {
  bottom: 150px;
  left: 35px;
}
.ag-wheel_lamp-5 {
  right: 35px;
  bottom: 150px;
}
.ag-wheel_lamp-8 {
  bottom: 38px;
  left: 145px;
}
.ag-wheel_lamp-6 {
  right: 145px;
  bottom: 38px;
}
.ag-wheel_lamp-7 {
  bottom: 1px;
  left: calc(50% - 16px / 2);
}
/* Adjsut the laps for bigger screen*/
@media screen and (min-width: 1400px) {
  .ag-wheel_lamp-8 {
    /* Adjust the position for larger screens */
    bottom: 50px;
    left: 160px;
  }
  .ag-wheel_lamp-6 {
    right: 180px;
    bottom: 38px;
  }
  .ag-wheel_lamp-7 {
    bottom: 2px;
    left: calc(50% - 16px / 2);
  }
  .ag-wheel_lamp-1 {
    top: 26px;
    left: 205px;
  }
  .ag-wheel_lamp-2 {
    top: 26px;
    right: 205px;
  }
  .ag-wheel_lamp-11 {
    top: 121px;
    left: 75px;
  }
  .ag-wheel_lamp-3 {
    top: 121px;
    right: 79px;
  }
  .ag-wheel_lamp-10 {
    top: 320px;
    left: 3px;
  }
  .ag-wheel_lamp-4 {
    top: 240px;
    right: 15px;
  }
  .ag-wheel_lamp-9 {
    bottom: 150px;
    left: 55px;
  }
  .ag-wheel_lamp-5 {
    right: 55px;
    bottom: 150px;
  }
}
@media screen and (max-width: 767px) {
  .ag-wheel_lamp-8 {
    /* Adjust the position for larger screens */
    bottom: 160px;
    left: 310px;
    z-index: -3;
  }
  .ag-wheel_lamp-6 {
    right: 230px;
    bottom: 18px;
  }
  .ag-wheel_lamp-7 {
    bottom: 2px;
    left: calc(50% - 16px / 2);
  }
  .ag-wheel_lamp-1 {
    top: 11px;
    left: 205px;
  }
  .ag-wheel_lamp-2 {
    top: 9px;
    right: 205px;
  }
  .ag-wheel_lamp-11 {
    top: 60px;
    left: 35px;
  }
  .ag-wheel_lamp-3 {
    top: 60px;
    right: 35px;
  }
  .ag-wheel_lamp-10 {
    top: 240px;
    left: 25px;
  }
  .ag-wheel_lamp-4 {
    top: 240px;
    right: 25px;
  }
  .ag-wheel_lamp-9 {
    bottom: 150px;
    left: 3px;
  }
  .ag-wheel_lamp-5 {
    right: 75px;
    bottom: 25px;
  }
}
.ag-wheel_num-stand {
  z-index: -1;
  height: 100px;
  width: 110px;
  background-color: #be8a0e;

  position: absolute;
  bottom: -98px;
  left: calc(50% - 110px / 2);

  -webkit-box-shadow: inset 0 85px 33px -60px rgba(0, 0, 0, 0.83);
  -moz-box-shadow: inset 0 85px 33px -60px rgba(0, 0, 0, 0.83);
  -o-box-shadow: inset 0 85px 33px -60px rgba(0, 0, 0, 0.83);
  box-shadow: inset 0 85px 33px -60px rgba(0, 0, 0, 0.83);
}
.ag-wheel_num-stand:before {
  content: "";
  height: 45px;
  width: 304px;
  background-color: #ffb810;

  position: absolute;
  bottom: 0;
  left: -97px;

  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}
.ag-wheel_num-stand:after {
  content: "";
  height: 17px;
  width: 322px;
  background-color: #996d06;

  position: absolute;
  bottom: 0;
  left: -106px;

  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;

  -webkit-box-shadow: inset 0 -3px 7px 0 rgba(0, 0, 0, 0.66);
  -moz-box-shadow: inset 0 -3px 7px 0 rgba(0, 0, 0, 0.66);
  -o-box-shadow: inset 0 -3px 7px 0 rgba(0, 0, 0, 0.66);
  box-shadow: inset 0 -3px 7px 0 rgba(0, 0, 0, 0.66);
}
.ag-wheel_num-stand_shadow {
  opacity: 0.08;

  bottom: -190px;

  -webkit-transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  transform: scaleY(-1);
}


@-webkit-keyframes an-shine {
  25% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
}
@-moz-keyframes an-shine {
  25% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
}
@-o-keyframes an-shine {
  25% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
}
@keyframes an-shine {
  25% {
    opacity: 0.5;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
}

